
import { actionTypes } from './constants';

const initialState = {
    selectedMeasurementTypeID: null,
    selectedTabId: null,
    trendKPIs: [],
    trendHealthStatuses: [],
    defaultHealthStatuses: {
        data: null,
        loadingStatus: null
    },
    calculatedThresholds: {
        loadingStatus: null
    },
    editState: {},
    saveOffset: {
        loadingStatus: null
    },
    saveAlertAlarm: {
        loadingStatus: null
    }
};

import {
    updateAlertValueOfMeasurementType,
    updateAlarmValueOfMeasurementType,
    updateCalibrationOffsetOfMeasurementType,
    updateHealthStatuses,
    updateHealthStatusesWithCalculatedTresholds,
    updateAlertNotificationOfMeasurementType,
    updateAlarmNotificationOfMeasurementType
} from './helpers';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_MEASUREMENT_TYPE_ID:
            return {
                ...state,
                selectedMeasurementTypeID: action.selectedMeasurementTypeID,
                defaultHealthStatuses: { ...initialState.defaultHealthStatuses }
            };
        case actionTypes.SET_SELECTED_TAB:
            return {
                ...state,
                selectedTabId: action.selectedTabId
            };
        case actionTypes.CLONE_TREND_KPIS:
            return {
                ...state,
                trendKPIs: [...action.trendKPIs]
            };
        case actionTypes.CLONE_HEALTH_STATUSES:
            return {
                ...state,
                trendHealthStatuses: [...action.trendHealthStatuses]
            };
        case actionTypes.SET_ALERT_VALUE:
            return {
                ...state,
                trendHealthStatuses: updateAlertValueOfMeasurementType(state.trendHealthStatuses, action.measurementTypeID, action.value)
            };
        case actionTypes.SET_ALARM_VALUE:
            return {
                ...state,
                trendHealthStatuses: updateAlarmValueOfMeasurementType(state.trendHealthStatuses, action.measurementTypeID, action.value)
            };
        case actionTypes.SET_ALERT_NOTIFICATION:
            //measurementTypeID, alertNotification
            return {
                ...state,
                trendHealthStatuses: updateAlertNotificationOfMeasurementType(state.trendHealthStatuses, action.measurementTypeID, action.alertNotification)
            };
        case actionTypes.SET_ALARM_NOTIFICATION:
            return {
                ...state,
                trendHealthStatuses: updateAlarmNotificationOfMeasurementType(state.trendHealthStatuses, action.measurementTypeID, action.alarmNotification)
            };
        case actionTypes.SET_OFFSET_VALUE:
            return {
                ...state,
                trendKPIs: updateCalibrationOffsetOfMeasurementType(state.trendKPIs, action.measurementTypeID, action.value)
            };
        case actionTypes.GET_DEFAULT_HEALTH_STATUSES:
            return {
                ...state,
                defaultHealthStatuses: { ...state.defadefaultHealthStatuses, ...action }
            };
        case actionTypes.GET_CALCULATED_THRESHOLDS:
            return {
                ...state,
                calculatedThresholds: { ...state.calculatedThresholds, ...action },
                trendHealthStatuses: updateHealthStatusesWithCalculatedTresholds(state.trendHealthStatuses, action.data)
            };
        case actionTypes.RESET_HEALTH_STATUSES:
            return {
                ...state,
                trendHealthStatuses: updateHealthStatuses(state.trendHealthStatuses, action.defaultThresholds, action.measurementTypeID)
            };
        case actionTypes.SET_PARAM_EDIT_STATE:
            return {
                ...state,
                editState: { ...state.editState, ...action.editState }
            };
        case actionTypes.SAVE_OFFSET:
            return {
                ...state,
                saveOffset: { ...state.saveOffset, ...action }
            };
        case actionTypes.SAVE_ALERT_ALARM:
            return {
                ...state,
                saveAlertAlarm: { ...state.saveAlertAlarm, ...action }
            };
        case actionTypes.CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
