import PropTypes from 'prop-types';
import React from 'react';
import { map } from 'lodash';

import ExpandableParameter from './../ExpandableParameter';
import SelectedParameter from './SelectedParameter';
import DirtyFlagCalculation from './DirtyFlagCalculation';

const HealthParametersComponent = (props) => {
    const { actions, model: { healthParameters, selectedKpi, autoDetectionIntervals } } = props;

    return <>
        {
            map(healthParameters, (kpi) => {
                return <DirtyFlagCalculation actions={actions}
                    kpi={kpi}
                    key={kpi.label}
                    render={(resetDirtyFlag) => {
                        return <ExpandableParameter
                            kpi={kpi}
                            selectedKpi={selectedKpi}
                            actions={actions}
                        >
                            {selectedKpi ?
                                <SelectedParameter
                                    resetDirtyFlag={resetDirtyFlag}
                                    key={selectedKpi.measurementTypeID}
                                    actions={actions}
                                    selectedKpi={selectedKpi}
                                    autoDetectionIntervals={autoDetectionIntervals} /> : null}
                        </ExpandableParameter>;
                    }}
                >
                </DirtyFlagCalculation>;
            })
        }
    </>;
};

HealthParametersComponent.propTypes = {
    actions: PropTypes.object
};


export default HealthParametersComponent;
