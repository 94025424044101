import { ComponentApi } from 'api';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
//import { getSaveOffsetRequestList, mapToOffsetReqObj } from './helpers';

const saveOffsetAction = getApiActionCreator(actionTypes.SAVE_OFFSET);

export const saveOffset = (asset, measurementCalibrationList) => {
	return (dispatch) => {
		dispatch(saveOffsetAction(apiCallStatus.LOADING));
		return ComponentApi.saveMeasurementCalibration(asset, measurementCalibrationList)
			.then(() => {
				dispatch(saveOffsetAction(apiCallStatus.SUCCESS));
			})
			.catch((error) => {
				dispatch(saveOffsetAction(apiCallStatus.ERROR, null, error));
			});
	};
};

const saveAlertAlarmAction = getApiActionCreator(actionTypes.SAVE_ALERT_ALARM);
export const saveAlertAlarmConfig = (asset, healthStatusList) => {
	return (dispatch) => {
		dispatch(saveAlertAlarmAction(apiCallStatus.LOADING));
		return ComponentApi.saveHealthStatusGroups(asset, healthStatusList)
			.then(() => {
				dispatch(saveAlertAlarmAction(apiCallStatus.SUCCESS));
			})
			.catch((error) => {
				dispatch(saveAlertAlarmAction(apiCallStatus.ERROR, null, error));
			});
	};
};
