import { find, transform } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import { TAB_SECTIONS } from './../constants';

import { getAlertValue, getAlarmValue, getNotificationStatus, getMinimumValue, getMaximumValue, getAutoDetectionIntervals } from './helpers';


const getSelectedMeasurementTypeId = ({ alertAlarmOffsetConfig }) => {
    return alertAlarmOffsetConfig.selectedMeasurementTypeID;
};


const getTrendHealthStatuses = ({ alertAlarmOffsetConfig }) => {
    return alertAlarmOffsetConfig.trendHealthStatuses;
};


const getHealthParameters = createSelector([
    ({ alertAlarmOffsetConfig }) => alertAlarmOffsetConfig.trendKPIs,
    getTrendHealthStatuses,
    ({ alertAlarmOffsetConfig }) => alertAlarmOffsetConfig.editState
],
    (
        kpiList,
        trendHealthStatuses,
        editState
    ) => {
        const sectionGroup = find(kpiList, { sectionId: TAB_SECTIONS.HealthParameters });
        let healthParameters = [];
        if (sectionGroup && trendHealthStatuses.length) {
            healthParameters = transform(sectionGroup.data, (acc, kpi) => {
                if (kpi.isEditable) {
                    const selectedMeasurementTypeID = kpi.measurementTypeID;
                    const healthStatusValues = find(trendHealthStatuses, (healthStatus) => {
                        return healthStatus.measurementTypeID === selectedMeasurementTypeID;
                    });
                    const config = {
                        alert: getAlertValue(healthStatusValues),
                        alarm: getAlarmValue(healthStatusValues),
                        min: getMinimumValue(healthStatusValues),
                        max: getMaximumValue(healthStatusValues),
                        offset: kpi.measurementCalibration,
                        precision: kpi.measurementTypePrecision,
                        notificationStatuses: getNotificationStatus(healthStatusValues)
                    };
                    const selectedKpiEditState = editState[selectedMeasurementTypeID];
                    acc.push({
                        ...kpi,
                        config,
                        editState: selectedKpiEditState
                    });
                }
            }, []);
        }
        return healthParameters;
    });

const getSelectedKpi = createSelector([
    getHealthParameters,
    getSelectedMeasurementTypeId,
],
    (
        opParameters,
        selectedMeasurementTypeID,
    ) => {
        const selectedKpi = find(opParameters, (kpi) => kpi.measurementTypeID === selectedMeasurementTypeID);
        return selectedKpi;
    });


const getValidAutoDetectionInterval = createSelector([
    ({ detailedInformation }) => detailedInformation.assetDetails.data
],
    (
        assetDetails
    ) => {
        let validIntervals = [];
        if (assetDetails?.details?.lastSyncTimestamp) {
            const autoDetectionIntervals = getAutoDetectionIntervals();
            const syncDate = moment(assetDetails.details.lastSyncTimestamp);

            if (syncDate.isValid) {
                validIntervals = transform(autoDetectionIntervals, (acc, intervalOption) => {
                    const dateInterval = moment();
                    dateInterval.subtract(intervalOption.value, 'days');
                    if (syncDate >= dateInterval) {
                        acc.push(intervalOption);
                    }
                }, []);
            }
        }
        return validIntervals;
    });

export const getModel = createSelector([
    getSelectedKpi,
    getHealthParameters,
    getValidAutoDetectionInterval
],
    (
        selectedKpi,
        healthParameters,
        autoDetectionIntervals,
    ) => {
        return {
            selectedKpi,
            healthParameters,
            autoDetectionIntervals
        };
    });
