import { useEffect } from 'react';

import useIsDirty from 'sharedHooks/useIsDirty';

import { alarmValidation, alertValidation } from './helpers';


const DirtyFlagCalculation = (props) => {

    const { actions, kpi } = props;
    const { config } = kpi;

    const [isDirty, setIsDirty] = useIsDirty([config]);

    const resetDirtyFlag = () => {
        setTimeout(() => {
            setIsDirty(false);
        }, 10);
    };

    useEffect(() => {
        const state = {
            isAlertValid: alertValidation(config.alert, config),
            isAlarmValid: alarmValidation(config.alarm, config),
            isDirty
        };
        actions.setParamEditState({ [kpi.measurementTypeID]: state });
    }, [isDirty, config.alert, config.alarm]);


    return props.render(resetDirtyFlag);
};

export default DirtyFlagCalculation;
