import { forEach } from 'lodash';
import { createSelector } from 'reselect';

import { TAB_SECTIONS } from './constants';

const getSelectedTabId = ({ alertAlarmOffsetConfig }) => {

	return alertAlarmOffsetConfig.selectedTabId ?
		alertAlarmOffsetConfig.selectedTabId :
		TAB_SECTIONS.HealthParameters;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
	return powertrainAssetDetails.selectedAsset;
};

const getTrendKPIsLoadingStatus = ({ operationalParameters }) => {
	return operationalParameters?.availableKPIs?.trend?.loadingStatus;
};

const getTrendHealthStatusesLoadingStatus = ({ operationalParameters }) => {
	return operationalParameters.trendHealthStatuses.loadingStatus;
};


const getAutoDetectionLoading = ({ alertAlarmOffsetConfig }) => {
	return alertAlarmOffsetConfig.calculatedThresholds.loadingStatus;
};

const getDefaultHealthStatusesLoading = ({ alertAlarmOffsetConfig }) => {
	return alertAlarmOffsetConfig.defaultHealthStatuses.loadingStatus;
};

const getSaveOffsetLoading = ({ alertAlarmOffsetConfig }) => {
	return alertAlarmOffsetConfig.saveOffset.loadingStatus;
};

const getSaveAlertAlarmLoading = ({ alertAlarmOffsetConfig }) => {
	return alertAlarmOffsetConfig.saveAlertAlarm.loadingStatus;
};

const getIsSaveAllDisabled = createSelector([
	({ alertAlarmOffsetConfig }) => alertAlarmOffsetConfig.editState,
],
	(
		editState
	) => {
		let changeFound = false;
		let invalidChangeFound = false;
		forEach(editState, (val) => {
			if (val.isDirty && !changeFound) {
				changeFound = val.isDirty;
			}
			invalidChangeFound = val.isDirty && (!val.isAlertValid || !val.isAlarmValid);

			return !invalidChangeFound;
		});
		return !changeFound || invalidChangeFound;
	});

export const getModel = createSelector([
	getSelectedTabId,
	getSelectedAsset,
	getTrendKPIsLoadingStatus,
	getTrendHealthStatusesLoadingStatus,
	getAutoDetectionLoading,
	getDefaultHealthStatusesLoading,
	getIsSaveAllDisabled,
	getSaveOffsetLoading,
	getSaveAlertAlarmLoading
],
	(
		selectedTabId,
		selectedAsset,
		trendKpisLoadingStatus,
		trendHealthStatusesLoadingStatus,
		autoDetectionLoadingStatus,
		defaultHealthStatusesLoading,
		isSaveAllDisabled,
		saveOffsetLoadingStatus,
		saveAlertAlarmLoadingStatus
	) => {
		return {
			selectedTabId,
			selectedAsset,
			trendKpisLoadingStatus,
			trendHealthStatusesLoadingStatus,
			autoDetectionLoadingStatus,
			defaultHealthStatusesLoading,
			isSaveAllDisabled,
			saveOffsetLoadingStatus,
			saveAlertAlarmLoadingStatus
		};
	});
