import { getTrendKPIsForAsset } from '../../commonActions';
import { getHealthStatuses } from '../../OperationalParameters/SingleChartView/Trend/TrendKPIList/actions';
import { actionTypes } from './constants';
import { getSaveOffsetRequestList } from './helpers';
import { saveAlertAlarmConfig, saveOffset } from './sharedActions';

export const setSelectedMeasurementTypeId = (selectedMeasurementTypeID) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.SET_SELECTED_MEASUREMENT_TYPE_ID, selectedMeasurementTypeID });
	};
};

export const setSelectedTab = (selectedTabId) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.SET_SELECTED_TAB, selectedTabId });
	};
};

export const cloneTrendKpis = () => {
	return (dispatch, getState) => {
		dispatch({ type: actionTypes.CLONE_TREND_KPIS, trendKPIs: getState().operationalParameters.availableKPIs.trend.data });
	};
};

export const cloneHealthStatuses = () => {
	return (dispatch, getState) => {
		dispatch({ type: actionTypes.CLONE_HEALTH_STATUSES, trendHealthStatuses: getState().operationalParameters.trendHealthStatuses.data });
	};
};


export const clearStore = () => {
	return (dispatch) => {
		dispatch({ type: actionTypes.CLEAR_STORE });
	};
};

export const setOffset = (measurementTypeID, value) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.SET_OFFSET_VALUE, measurementTypeID, value });
	};
};

export const setParamEditState = (editState) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.SET_PARAM_EDIT_STATE, editState });
	};
};

export const saveAllAlarmAlertOffsetConfig = () => {
	return (dispatch, getState) => {
		const state = getState();
		const asset = state.powertrainAssetDetails.selectedAsset;
		const trendHealthStatuses = state.alertAlarmOffsetConfig.trendHealthStatuses;
		const saveOffsetsReqList = getSaveOffsetRequestList(asset.componentID, state.alertAlarmOffsetConfig.trendKPIs);
		return dispatch(saveOffset(asset, saveOffsetsReqList))
			.then(() => {
				return dispatch(saveAlertAlarmConfig(asset, trendHealthStatuses));
			}).then(() => {
				return dispatch(getTrendKPIsForAsset(asset));
			}).then(() => {
				return dispatch(getHealthStatuses(asset));
			});
	};
};


